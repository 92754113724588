import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"

export default function IndexPage({ data }) {
  const { posts } = data.blog

  return (  
    <Layout>
      <div className="flex flex-col">
        <div>
          <h1 className="mt-12 text-3xl font-bold mb-4">jpren</h1>
          <p>Building Frens</p>
        </div>
        <div className="flex mt-12 flex-col">
          <div className="flex text-lg">Posts</div>
          {posts.map(post => (
            <article key={post.id}>
              <Link to={post.fields.slug}>
                <p className="mt-3 font-bold">{post.frontmatter.title}</p>
              </Link>
              {/* <small>
                {post.frontmatter.author}, {post.frontmatter.date}
              </small>
              <p>{post.excerpt}</p> */}
            </article>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(fromNow: true)
          title
          author
        }
        excerpt
        id
      }
    }
  }
`